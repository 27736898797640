.fs-08 {
    font-size: 0.8em !important;
}

.fs-09 {
    font-size: 0.9em !important;
}

.fs-10 {
    font-size: 1em !important;
}

.fs-12 {
    font-size: 1.2em !important;
}

.fs-14 {
    font-size: 1.4em !important;
}

.fs-16 {
    font-size: 1.6em !important;
}

.fs-18 {
    font-size: 1.8em !important;
}

.fs-19 {
    font-size: 1.9em !important;
}

.fs-20 {
    font-size: 2em !important;
}

.fs-22 {
    font-size: 2.2em !important;
}

.fs-24 {
    font-size: 2.4em !important;
}

.fs-26 {
    font-size: 2.6em !important;
}

.fs-28 {
    font-size: 2.8em !important;
}

.fs-30 {
    font-size: 3em !important;
}

.fs-32 {
    font-size: 3.2em !important;
}

.fs-34 {
    font-size: 3.4em !important;
}

.fs-36 {
    font-size: 3.6em !important;
}

.fs-38 {
    font-size: 3.8em !important;
}

.font-bold {
    font-weight: bold !important;
}

.text-vertical {
    transform: rotate(180deg);
    writing-mode: vertical-rl;
    text-orientation: mixed;
    text-align: center;
}

.text-diagonal {
    transform: rotate(45deg);
    writing-mode: sideways-lr;
    text-orientation: mixed;
    /* text-align: center; */
}

.text-end {
    text-align: end !important;
}

.text-start {
    text-align: start !important;
}

.text-center {
    text-align: center !important;
}

.mat-icon-36 {
    font-size: 36px !important;
    height: 36px !important;
    width: 36px !important;
}

.mat-icon-custom-pending-actions {
    font-size: 36px !important;
    height: 32px !important;
    width: 32px !important;
    line-height: 32px !important;
}
.highlight {
    font-weight: bold;
    color: #2b577f;
}

@media screen and (max-width: 575px) {

    .h1-dynamic-size {
        font-size: 24px;
    }
    .h3-dynamic-size {
        font-size: 18px;
    }
    .h5-dynamic-size {
        font-size: 14px;
    }
}

@media screen and (min-width: 575px) and (max-width: 767px) {

    .h1-dynamic-size {
        font-size: 26px;
    }
    .h3-dynamic-size {
        font-size: 20px;
    }
    .h5-dynamic-size {
        font-size: 15px;
    }
}

/* sm */
@media screen and (min-width: 767px) and (max-width: 960px) {

    .h1-dynamic-size {
        font-size: 28px;
    }
    .h3-dynamic-size {
        font-size: 23px;
    }
    .h5-dynamic-size {
        font-size: 16px;
    }
}

/* md */
@media screen and (min-width: 960px) and (max-width: 1200px) {

    .h1-dynamic-size {
        font-size: 32px;
    }
    .h3-dynamic-size {
        font-size: 25px;
    }
    .h5-dynamic-size {
        font-size: 17px;
    }
}

/* lg */
@media screen and (min-width: 1200px) and (max-width: 1600px) {

    .h1-dynamic-size {
        font-size: 34px;
    }
    .h3-dynamic-size {
        font-size: 25px;
    }
    .h5-dynamic-size {
        font-size: 18px;
    }
}

/* xl */
@media screen and (min-width: 1600px) {
    .h1-dynamic-size {
        font-size: 36px;
    }
    .h3-dynamic-size {
        font-size: 25px;
    }
    .h5-dynamic-size {
        font-size: 18px;
    }
}

.mat-input-element {
    line-height: 1.4em !important;
}