// input[type="text"],
// input[type="time"] {
//   // -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.075);
//   // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.075);
//   border: 1px solid #dedede;
//   border-radius: 2px;
//   height: auto;
//   width: 100%;
//   padding: 6px 6px 6px 6px;
//   color: #555;
//   resize: none;
//   -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
//   -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
//   transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
// }

// input[disabled=""] {
//   background-color: #f5f5f5 !important;
// }

// input[type="text"]:focus {
//   border-color: #66afe9;
//   outline: 0;
//   -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
//   box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
// }

.container,
.titles-container {
    padding: 0 10px 6px;
}

.asterix {
    left: 0;
    background-color: #337ab7;
    // background-color: #a94442;
    // clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 74%, 0 100%);
    clip-path: polygon(100% 0, 100% 100%, 61% 84%, 24% 100%, 24% 17%, 0 17%, 24% 0);
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    top: -3.5px;
    left: -4px;
}

// v2 
// .asterix { 
//     left: 0;
//     background-color: #eb3b31;
//     clip-path: polygon(100% 0, 100% 100%, 61% 84%, 24% 100%, 24% 17%, 0 17%, 24% 0);
//     width: 4px;
//     height: 100%;
//     display: block;
//     position: absolute;
//     top: -3.5px;
//     left: 0px;
//     border-bottom-left-radius: 5px;
//     border-top-left-radius: 5px;
// }
.asterix::after {
    content: "*";
    color: white;
    font-size: 20px;
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    top: -2px;
    left: 7px;
}

.question-number:not(.mandatory) {
    padding-top: 7px !important;
}

.question-number.mandatory {
    padding-top: 18px;
}

.label-text {
    font-size: 16px !important;
    flex: 1 1 100% !important;
    font-weight: bold !important;
}

.label-text:not(.mandatory) {
    padding: 6px 0 5px !important;
}

.label-text.mandatory {
    padding: 17px 0 5px;
}

.form-help-text {
    padding-left: 0;
    color: #5f5f5f;
    font-size: 12px;
    display: block;
    margin-top: -3px;
    // margin-bottom: 7px !important;
    clear: both;
    float: none;
}

.form-group {
    padding-top: 10px;
}

.form-group,
.question {
    z-index: 1;
}

.form-field-special {
    margin-top: 18px;
}

.alternative-image,
.img-survey {
    margin: 0 auto !important;
    max-width: 100%;
    display: block;
    height: auto;
}

// .img-survey {
//     border-top-left-radius: 5px;
//     border-top-right-radius: 5px;
// }

@media screen and (max-width: 600px) {
    .data_field {
        text-align: center;
        line-height: 14px;
        vertical-align: middle;
        clear: left;
        padding: 0;
    }

    .data_field span {
        vertical-align: middle;
        // float: left;
    }

    .data_field input {
        vertical-align: middle;
        // margin: 12px auto !important;
        // float: left !important;
    }

    .radio_btns .data_field label input[type="radio"] {
        margin-top: 6px;
    }

}

@media (max-height: 680px) {
    .form-group {
        margin-bottom: 2px !important;
    }

    // .field-wrapper label {
    //     padding-top: 2px !important;
    // }
}

// label.label_checkbox {
//   width: auto !important;
// }

.radio_btns .data_field label input[type="radio"] {
    margin-top: 4px;
}

.radio_btns .data_field.input_colors label {
    background-image: none;
}


body .field-wrapper label {
    font-size: 16px !important;
}

.data_field input {
    vertical-align: middle;
    display: inline-block;
    margin: 0;
}

.data_field input[type="radio"],
.data_field input[type="checkbox"] {
    margin: 0;
    height: 15px;
}

.data_field input.form-control {
    margin-bottom: 7px;
}

.data_field input[type="radio"],
.data_field input[type="checkbox"] {
    margin: 10px 0;
    width: 14px;
}

.data_field .no_apply {
    width: 120px !important;
    max-width: 120px !important;
    line-height: 0 !important;
}

.radio_btns .data_field label {
    width: 13.285714%;
    margin: 0;
    padding: 0 5px;
    line-height: 32px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    border: 1px solid #dbdbdb;
    background-image: -webkit-gradient(linear, left 0, left 100%, from(#fff), to(#e0e0e0));
    background-image: -webkit-linear-gradient(top, #fff 0, #e0e0e0 100%);
    background-image: -moz-linear-gradient(top, #fff 0, #e0e0e0 100%);
    background-image: linear-gradient(to bottom, #fff 0, #e0e0e0 100%);
    background-repeat: repeat-x;
}

*,
:after,
:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

input[type="checkbox"],
input[type="radio"] {
    margin: 4px 0 0;
    margin-top: 1px\9;
    line-height: normal;
}

input[type="checkbox"]:focus,
input[type="radio"]:focus {
    outline: -webkit-focus-ring-color auto 5px;
    outline-offset: -2px;
}

.checkbox,
.radio {
    position: relative;
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
}

.checkbox label,
.radio label {
    min-height: 20px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    cursor: pointer;
}

.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"],
.radio input[type="radio"] {
    position: absolute;
    margin-left: -20px;
    margin-top: 4px\9;
}

.checkbox+.checkbox,
.radio+.radio {
    margin-top: -5px;
}

.checkbox-inline {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    margin-bottom: 0;
    vertical-align: middle;
    font-weight: 400;
    cursor: pointer;
}

.checkbox-inline+.checkbox-inline {
    margin-top: 0;
    margin-left: 10px;
}

.checkbox-inline.disabled,
.checkbox.disabled label,
.radio.disabled label,
fieldset[disabled] .checkbox label,
fieldset[disabled] .checkbox-inline,
fieldset[disabled] .radio label,
fieldset[disabled] input[type="checkbox"],
fieldset[disabled] input[type="radio"],
input[type="checkbox"].disabled,
input[type="checkbox"][disabled],
input[type="radio"].disabled,
input[type="radio"][disabled] {
    cursor: not-allowed;
}

@-ms-viewport {
    width: device-width;
}

.container,
body {
    display: block;
}

label {
    font-weight: 400;
}

// v2
.field-wrapper {
    position: relative;
}

.field-wrapper label {
    // margin: 10px 0 10px;
    // font-weight: 400 !important;
    margin-bottom: 0 !important;
}


.data_field {
    text-align: center !important;
}

.data_field span {
    width: 100% !important;
}

div.no_input div.no_apply_notes_container {
    color: #333;
    margin-top: 40px;
}

.no_apply_notes_container {
    width: 100%;
    background: #f6f6f6;
    padding: 2px 7px 4px;
    border-radius: 5px;
    // box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    cursor: pointer;
    -webkit-transition: box-shadow 1s linear;
    -moz-transition: box-shadow 1s linear;
    -o-transition: box-shadow 1s linear;
    transition: box-shadow 1s linear;
}

.no_apply_notes_container.selected {
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
    // box-shadow: #e6e6e6 1px 1px 4px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    // font-weight: bold;
    background-color: #e0e0e0 !important;
    // color: #337ab7;
}

.label_no_apply>.no_apply_option,
.no_apply_notes_container>.no_apply_option,
.face_option_no_apply .no_apply_option {
    display: none;
}

.face_option_no_apply .selected {
    // color: #337ab7 !important;
    color: #3d3d33 !important;
}

.no_apply_text {
    position: relative;
    bottom: -2px;
    color: #666;
}

.select-item-container {
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-transition: all .15s ease-in-out;
    -o-transition: all .15s ease-in-out;
    -webkit-transition: all .15s ease-in-out;
    text-align: left;
    margin-bottom: 3px;
    // border: 1px solid #d5d5d5;
    background: #f4f4f4;
    color: #666;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    overflow: clip !important;
}

.label-place {
    padding: 0 5px;
    border-radius: 5px;

    .label-place-value {
        font-weight: bold;
    }
}

.mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: rgba(43, 87, 127, 0.05);
}

.mat-form-field-flex {
    padding-top: 0 !important;
}

.answer-text {
// opt 1
    // background: #fff69d;
    // min-width: 280px;
    // padding: 3px 5px;
    // border-radius: 6px;
    // width: 100%;
// opt 2 
    // text-align: center;
    // padding: 4px 5px;
    // border-radius: 4px;
    // width: 100%;
    // color: #337ab7;
    // border: 2px solid #337ab7;
// opt 3
    position: relative;
    background: #f4f4f4;
    padding: 5px 10px;
    border-radius: 5px;
    color: #337ab7; // new 
    // color: #2b577f; old
    margin-bottom: 5px;
}

.option-separator {
    height: 100%;
    width: 3px;
    margin-right: 10px !important;
    // background-color: #f6f6f6;
    background-color: #999;
    border-right: none !important;
}

@media screen and (max-width: 497px) {
    .option-separator {
        margin-left: 5px !important;
        margin-right: 5px !important;
        // background-color: red;
    }

}

.c-no-apply {
    color: #999;
}

@media screen and (max-width: 576px) {
    .label-text.mandatory {
        padding: 17px 0 0 !important;
    }

    .label-text:not(.mandatory) {
        padding: 4px 0 0 !important;
    }

    .titles-container {
        padding: 0 10px 0px !important;
    }

    .form-group {
        padding-top: 2px !important;
    }

    .form-field-special {
        margin-top: 0 !important;
    }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
    .label-text.mandatory {
        padding: 17px 0 0 !important;
    }

    .label-text:not(.mandatory) {
        padding: 4px 0 0 !important;
    }

    .titles-container {
        padding: 0 10px 0px !important;
    }

    .form-group {
        padding-top: 2px !important;
    }

    .form-field-special {
        margin-top: 0 !important;
    }
}

/* sm */
@media screen and (min-width: 769px) and (max-width: 959px) {
    .label-text.mandatory {
        padding: 17px 0 0 !important;
    }

    .label-text:not(.mandatory) {
        padding: 4px 0 0 !important;
    }

    .titles-container {
        padding: 0 10px 0px !important;
    }

    .form-group {
        padding-top: 2px !important;
    }

    .form-field-special {
        margin-top: 0 !important;
    }
}

/* md */
@media screen and (min-width: 960px) and (max-width: 1199px) {}

/* lg */
@media screen and (min-width: 1200px) and (max-width: 1599px) {}

/* xl */
@media screen and (min-width: 1600px) {}

.character-counter {
    position: absolute;
    right: -10px;
    bottom: -20px;
    font-size: 12px;
    color: #828282;
}
