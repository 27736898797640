@use '@angular/material'as mat;

@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 300;
    src: url("assets/fonts/open-sans/OpenSans-regular.ttf");
    font-display: block;
}

@font-face {
    font-family: "Open Sans Light";
    font-style: normal;
    font-weight: 300;
    src: url("assets/fonts/open-sans/OpenSans-Light.ttf");
    font-display: block;
}

@font-face {
    font-family: "Open Sans Bold";
    font-style: normal;
    font-weight: 300;
    src: url("assets/fonts/open-sans/OpenSans-Bold.ttf");
    font-display: block;
}

html {
    margin: 0;
    overflow-x: hidden !important;
    // scrollbar-gutter: stable;
}

body,
hr,
html,
ul {
    padding: 0;
}

body,
html {
    padding: 0;
    touch-action: pan-y pinch-zoom;
}

body {
    // background-color: rgb(245, 245, 245);
    background-color: #eaeaea;
    margin: 0 !important;
    font-family: "Open Sans" !important;
    line-height: 1.42857143;
    color: #333;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    font-size: 16px;
    height: 100%;
}

body * {
    outline: 0 !important;
    // text-decoration: none;
}

.background-login {
    margin-bottom: 90px;
    background: #015788;
    background: -moz-radial-gradient(center, ellipse cover, #015788 1%, #2d4961 100%);
    background: -webkit-gradient(radial,
            center center,
            0,
            center center,
            100%,
            color-stop(1%, #015788),
            color-stop(100%, #2d4961));
    background: -webkit-radial-gradient(center, ellipse cover, #015788 1%, #2d4961 100%);
    background: -o-radial-gradient(center, ellipse cover, #015788 1%, #2d4961 100%);
    background: -ms-radial-gradient(center, ellipse cover, #015788 1%, #2d4961 100%);
    background: radial-gradient(ellipse at center, #015788 1%, #2d4961 100%);
}

/* You can add global styles to this file, and also import other style files */
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
}

h3 {
    font-size: 25px;
    margin: 15px 0;
    color: #333;
    font-weight: 700;
}

h5 {
    margin: 7px 0 5px;
    color: #444;
    font-weight: lighter;
    font-family: "Open Sans Light";
}

#header2 h3,
#header2 h5 {
    margin: 7px 0 0 0;
}

.font-18 {
    font-size: 18px !important;
}

.wp-100 {
    width: 100%;
}

a {
    color: #2b577f;
}

a:focus,
a:hover {
    color: #005eb1;
    text-decoration: underline;
}

.qs_footer {
    position: absolute;
    bottom: 0;
    margin: 12px auto;
    font-size: 12px;
}

.matrix_alternatives .matrix_single_item,
.qs_footer {
    text-align: center;
}


.btn-group-vertical>.btn-group::after,
.btn-toolbar::after,
.clearfix::after,
.container-fluid::after,
.container::after,
.dl-horizontal dd::after,
.form-horizontal .form-group::after,
.modal-footer::after,
.modal-header::after,
.row::after {
    clear: both;
}

.btn-group-vertical>.btn-group::after,
.btn-group-vertical>.btn-group::before,
.btn-toolbar::after,
.btn-toolbar::before,
.clearfix::after,
.clearfix::before,
.container-fluid::after,
.container-fluid::before,
.container::after,
.container::before,
.dl-horizontal dd::after,
.dl-horizontal dd::before,
.form-horizontal .form-group::after,
.form-horizontal .form-group::before,
.modal-footer::after,
.modal-footer::before,
.modal-header::after,
.modal-header::before,
.row::after,
.row::before {
    content: " ";
    display: table;
}

.btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    border: 1px solid transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.btn-previus.active {
    background: #929292;
    border-color: #b0b0b0;
}

.btn-previus,
.btn-success {
    color: #fff !important;
}

.btn-success.active {
    background: #69ab32;
    border-color: #97c17d;
}

textarea,
input,
button,
select {
    font-family: inherit;
    font-size: inherit;
}

.confetti-button span {
    pointer-events: none !important;
}

app-thanks {
    height: 100vh !important;
    display: block;
}

app-progress-bar,
app-banner,
app-question-page,
app-end-title-form,
app-question,
app-translate,
app-title-page,

app-modal-terms-and-conditions,
app-modal-validate-otp,
app-modal-confirm,

app-audio,
app-combobox,
app-file,
app-html,
app-photo,
app-ranking,
app-scale-likert,
app-scale-faces,
app-scale-numeric,
app-scale-stars,
app-selection-single,
app-selection-multi,
app-terms-and-conditions,
app-text-multi,
app-text-single,
app-yes-no,
app-matrix-scale-stars,
app-matrix-scale-numeric,
app-matrix-scale-faces,
app-matrix-double-entry,
app-maxdiff,
app-like-dislike,
app-digital-signature,
app-commitments,

app-preview,

app-sensor-form,
app-sensor-text-single,
app-calendly,
app-pinpad,

app-surveys {
    width: 100% !important;
}


// for checkbox
app-selection-multi,
app-terms-and-conditions {

    .mat-checkbox-layout {
        min-width: 100% !important;
        white-space: normal !important;
    }

    .mat-checkbox-inner-container {
        margin-left: 0 !important;
        margin-top: 4px;
    }

}

signature-pad {
    background-color: white !important;
}

.signature-pad-canvas {
    // box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    border: 1px solid #dedede !important;
    border-radius: 5px;
    // background: #f4f4f4;
    // width: 100%;
    // color: #555;
}

app-widget-admin {
    position: absolute;
    z-index: 99;
    bottom: 25px;
    right: 0px;
}

app-question-page {
    position: relative;
}

app-modal-terms-and-conditions {
    embed {
        width: 100%;
        height: 100%;
    }
}

app-modal-otp-validation {
    app-loading-screen {
        position: relative;
        z-index: -1;
        pointer-events: none;
    }

    code-input>span {
        padding-right: 0 !important;
    }
}

.modal-terms-and-conditions,
.modal-validation-otp,
.modal-confirm,
.modal-calendly,
.modal-share-survey,
.modal-process-pending-answers {
    justify-content: center;

    mat-dialog-container {
        padding: 0 !important;
        overflow: inherit !important;
        background-color: transparent;
    }
}

app-modal-process-pending-answers {
    .mat-list-item-disabled {
        background-color: #eee !important;

        mat-pseudo-checkbox {
            display: none !important;
        }
    }
}

.modal-validation-otp {
    mat-dialog-container {
        width: auto;
    }

}

.modal-calendly {
    overflow: hidden;
    height: auto;
}

@media screen and(min-width: 960px) {
    .modal-terms-and-conditions {
        width: 940px !important;
        height: 90vh;
    }

    .modal-validation-otp {
        width: 940px !important;
    }
}

@media screen and(max-width: 959px) {
    .modal-terms-and-conditions {
        width: 95% !important;
        height: 90vh;
        max-width: 90vw !important;
    }

    .modal-validation-otp {
        width: 940px !important;
        max-width: 90vw !important;
    }

}

@media screen and(min-width: 599px) {
    app-surveys {
        height: calc(100vh - 64px) !important;
    }
}

app-surveys {
    display: block;
    height: calc(100vh - 56px);
}

app-photo,
app-preview {
    mat-accordion {
        width: 100% !important;
    }

    mat-expansion-panel {
        width: 100% !important;
        box-shadow: none !important;
        border: 1px solid #dedede !important;
    }

    mat-expansion-panel-header {
        height: 40px !important;
        background-color: #f5f5f5 !important;
    }

    .mat-expansion-panel-body {
        padding: 0 !important;
    }
}

app-preview {
    mat-expansion-panel-header {
        background-color: #e8ebef !important;
    }
}


.mat-menu-content {
    padding: 0 !important;
}

// app-scale-faces {
//     .mat-form-field-infix {
//         padding: 0.1em 0px !important;
//         border-top: 0 !important;
//     }

//     .mat-form-field-label {
//         margin-top: -15px;
//     }

//     label.ng-star-inserted {
//         transform: translateY(-0.59375em) scale(.75) !important;
//     }
// }

mat-nav-list:not([role="navigation"]).list-item-active {
    background-color: #5b6c828a;
}

.no-shadow {
    box-shadow: none !important;
}

.mat-drawer-side {
    border-right: none !important;
    box-shadow: 1px 1px 3px #3333334a !important;
}

mat-sidenav-container,
mat-sidenav {
    height: 100% !important;
}



.hide-input {
    position: absolute;
    height: 0 !important;
    width: 0 !important;
    padding: 0 !important;
    pointer-events: none;
    opacity: 0;
}

code-input {
    background-color: #fbfbfb;
    z-index: 2 !important;
}

code-input>span {
    background-color: white;
    border-radius: 5px !important;
}

@media screen and(min-width: 960px) {
    code-input {
        padding: 18px 20px;
    }

    code-input>span {
        margin: 0 20px;
    }
}

@media screen and(max-width: 959px) {
    code-input {
        padding: 5px 6px;
    }

    code-input>span {
        margin: 0 5px;
    }
}

@media screen and (max-width: 1100px) {
    .grecaptcha-badge {
        visibility: hidden !important;
    }
}

.grecaptcha-badge {
    z-index: 99 !important;
    bottom: 100px !important;
    visibility: hidden !important;
}

code-input>span>input {
    border: 1px solid #dedede !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s !important;
    color: #555 !important;
    --item-spacing: 0px !important;
    // --item-height: 3em !important;
    // --item-border: 1px solid #dddddd;
    // --item-border-bottom: 2px solid #dddddd !important;
    // --item-border-has-value: none !important;
    // --item-border-bottom-has-value: 2px solid #888888 !important;
    // --item-border-focused: none !important;
    // --item-border-bottom-focused: 2px solid #2c43c4 !important;
    // --item-shadow-focused: none !important;
    // --item-border-radius: 0px !important;
}

code-input>span>input:focus {
    border-color: #66afe9 !important;
    outline: 0 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6) !important;
}

code-input[ng-reflect-disabled=true]>span {
    background-color: #efefef;
    padding-left: 0 !important;
}

.snack-confirm {
    color: white !important;

    .mat-simple-snackbar-action {
        color: white !important;
    }
}

.pointer {
    cursor: pointer;
}

.snack-white {
    .mat-simple-snackbar-action {
        color: white !important;
    }
}

.snack-qservus-blue {
    // background: #fcbe03 !important;
    // color: #34465b !important;
    background: #1E5783 !important;
    color: #fff !important;
    // font-weight: bold;
    position: relative;

    &::after {
        content: "";
        display: block;
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        background-image: url('./assets/imgs/QServus.svg') !important;
        // filter:hue-rotate(100deg);
        // -webkit-mask:linear-gradient(to bottom right,#fff,transparent);
        background-size: contain;
        background-repeat: no-repeat;
        z-index: -1;
        opacity: 0.1;
    }

    .mat-button {
        // font-weight: bold !important;
        color: #F4F4F4 !important;
    }
}

@media screen and (max-width: 599px) {
    .snack-fixed {
        left: 0;
    }
}

.snack-fixed {
    position: fixed !important;
    bottom: 0 !important;
    transform: scale(1) translateX(-50%) !important;
}

.spinner-center {
    position: absolute !important;
    margin: auto !important;
}

.checkbox label {
    word-break: break-word;
    white-space: normal;
    padding-left: 0 !important;
}

// custom element colors
mat-icon.mat-white {
    color: white;
}

mat-icon.mat-grey {
    color: #6d6d6d;
}

mat-icon.mat-fav {
    color: #ee3529 !important;
}

mat-icon.mat-commit,
mat-button.mat-commit {
    color: #337ab7 !important;
}

mat-icon.mat-placeholder {
    color: rgba(0, 0, 0, .42);
}

mat-sidenav-container {
    >.mat-drawer-backdrop.mat-drawer-shown {
        background-color: #00000099;
    }

    .mat-drawer-inner-container {
        display: flex;
    }
}

.mat-standard-chip {
    border-radius: 5px !important;
    cursor: pointer !important;
    transition: width .5s ease-in !important;
}

.mat-standard-chip:not(.mat-chip-selected) {
    background-color: #e7eaf0 !important;
}

.mat-chip.mat-filter.mat-chip-selected {
    background-color: #d1e5f5 !important;
    color: #2b577f !important;
}

.mat-menu-panel {
    min-height: auto !important;
}

button.mat-qservus-primary {
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
}

button.mat-darkgrey {
    color: #3d3d3d;
    border-color: #3d3d3d;
}

.cdk-global-overlay-wrapper {
    height: 100vh !important;
}

.pac-logo::after {
    display: none !important;
}

.mat-success:not(.mat-button-disabled, .mat-icon) {
    background-color: #5cb85c !important;
    color: #fff !important;
}

.img-title-resp {
    max-width: 100%;
    margin: auto;
    display: block;
    border-radius: 5px;
}

.small-image {
    max-height: 20vh;
}

//show only first error message
mat-form-field {
    mat-error {
        display: none !important;
    }

    mat-error:first-child {
        display: block !important;
    }

    mat-hint {
        line-height: 1.2 !important;
    }
}

app-commitments {
    .mat-form-field-infix {
        border-top: 0 !important;
        padding: 10px 0 !important;
    }

    .mat-form-field-prefix {
        margin-right: 2px;
    }

}

@import "~@angular/material/theming";
@import "~material-icons/iconfont/material-icons.scss";
// @import '~material-design-icons/iconfont/material-icons.css';

$custom-typography: mat.define-typography-config($font-family: "Open Sans",
);
// Override typography CSS classes (e.g., mat-h1, mat-display-1, mat-typography, etc.).
@include mat.typography-hierarchy($custom-typography);
// Override typography for a specific Angular Material components.
@include mat.checkbox-typography($custom-typography);
// Override typography for all Angular Material, including mat-base-typography and all components.
@include mat.all-component-typographies($custom-typography);

@import './assets/style/form-fields.scss';
// @import './assets/style/backgrounds.scss';
@import "./theme.scss";
