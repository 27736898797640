.loading-page {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  display: flex;
  align-items: center;
  z-index: 99;
  background-color: #d3d3d375;
  animation: fadeIn .8s ease-in both;
}

@keyframes fadeIn {
	from {
    opacity: 1;
	}
	to {
		opacity: 0;
	}
}

.logo-qservus {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
}
.loading-page.darker {
  background-color: #00000060 !important;
}

.loading-page>.loading-content {
  display: inline-grid;
  text-align: center;
  justify-items: center;
}

.half-circle-spinner, .half-circle-spinner * {
  box-sizing: border-box;
}

.half-circle-spinner {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  position: relative;
}

.half-circle-spinner .circle {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 3px solid transparent;
}

.half-circle-spinner .circle.circle-1 {
  /* border-top-color: #244596; */
  border-top-color: #244596;
  animation: half-circle-spinner-animation 1s infinite;
  box-shadow: inset 0px 15px 14px -15px #fff;
}

.half-circle-spinner .circle.circle-2 {
  border-bottom-color: #FEBF00;
  animation: half-circle-spinner-animation 1s infinite alternate;
  box-shadow: inset 0px -15px 14px -15px #fff;
}

@keyframes half-circle-spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.container-text-loading {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px 10px;
  border-radius: 15px;
}