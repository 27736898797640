/* padding */

.pa-0 {
    padding: 0 !important;
}

.pa-05 {
    padding: 5px !important;
}

.pa-1 {
    padding: 10px !important;
}

.pa-2 {
    padding: 20px !important;
}

.pa-3 {
    padding: 30px !important;
}

.pa-4 {
    padding: 40px !important;
}

.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.px-05 {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.px-1 {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.px-2 {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.px-3 {
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.px-4 {
    padding-left: 40px !important;
    padding-right: 40px !important;
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.py-05 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.py-1 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.py-2 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

.py-3 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}

.py-4 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pt-05 {
    padding-top: 5px !important;
}

.pt-1 {
    padding-top: 10px !important;
}

.pt-2 {
    padding-top: 20px !important;
}

.pt-3 {
    padding-top: 30px !important;
}

.pt-4 {
    padding-top: 40px !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pb-05 {
    padding-bottom: 5px !important;
}

.pb-1 {
    padding-bottom: 10px !important;
}

.pb-2 {
    padding-bottom: 20px !important;
}

.pb-3 {
    padding-bottom: 30px !important;
}

.pb-4 {
    padding-bottom: 40px !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pl-05 {
    padding-left: 5px !important;
}

.pl-1 {
    padding-left: 10px !important;
}

.pl-2 {
    padding-left: 20px !important;
}

.pl-3 {
    padding-left: 30px !important;
}

.pl-4 {
    padding-left: 40px !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pr-05 {
    padding-right: 5px !important;
}

.pr-1 {
    padding-right: 10px !important;
}

.pr-2 {
    padding-right: 20px !important;
}

.pr-3 {
    padding-right: 30px !important;
}

.pr-4 {
    padding-right: 40px !important;
}

/* margin */

.ma-0 {
    margin: 0 !important;
}

.ma-05 {
    margin: 5px !important;
}

.ma-1 {
    margin: 10px !important;
}

.ma-2 {
    margin: 20px !important;
}

.ma-3 {
    margin: 30px !important;
}

.ma-4 {
    margin: 40px !important;
}

.mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.mx-05 {
    margin-left: 5px !important;
    margin-right: 5px !important;
}

.mx-1 {
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.mx-2 {
    margin-left: 20px !important;
    margin-right: 20px !important;
}

.mx-3 {
    margin-left: 30px !important;
    margin-right: 30px !important;
}

.mx-4 {
    margin-left: 40px !important;
    margin-right: 40px !important;
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.my-05 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.my-1 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.my-2 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.my-3 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}

.my-4 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-05 {
    margin-top: 5px !important;
}

.mt-1 {
    margin-top: 10px !important;
}

.mt-2 {
    margin-top: 20px !important;
}

.mt-3 {
    margin-top: 30px !important;
}

.mt-4 {
    margin-top: 40px !important;
}

.mt-5 {
    margin-top: 50px !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-05 {
    margin-bottom: 5px !important;
}

.mb-1 {
    margin-bottom: 10px !important;
}

.mb-2 {
    margin-bottom: 20px !important;
}

.mb-3 {
    margin-bottom: 30px !important;
}

.mb-4 {
    margin-bottom: 40px !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.ml-05 {
    margin-left: 5px !important;
}

.ml-1 {
    margin-left: 10px !important;
}

.ml-2 {
    margin-left: 20px !important;
}

.ml-3 {
    margin-left: 30px !important;
}

.ml-4 {
    margin-left: 40px !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.mr-05 {
    margin-right: 5px !important;
}

.mr-1 {
    margin-right: 10px !important;
}

.mr-2 {
    margin-right: 20px !important;
}

.mr-3 {
    margin-right: 30px !important;
}

.mr-4 {
    margin-right: 40px !important;
}

.float-left {
    float: left;
}
.float-right {
    float: right !important;
}
.relative {
    position: relative;
}
.d-flex {
    display: flex;
}
.just-cont-center {
    justify-content: center;
}
.z-index-1 {
    z-index: 1;
}
.z-index-2 {
    z-index: 2;
}