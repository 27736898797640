@use '@angular/material'as mat;
@import '@angular/material/theming';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts Material sIcons
// @import 'https://fonts.googleapis.com/css?family=Material+Icons';
// Foreground Elements

// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$light-theme-foreground-palette: (base: black,
    divider: $dark-dividers,
    dividers: $dark-dividers,
    disabled: $dark-disabled-text,
    disabled-button: rgba($dark-text, 0.26),
    disabled-text: $dark-disabled-text,
    elevation: black,
    secondary-text: $dark-accent-text,
    hint-text: $dark-disabled-text,
    accent-text: $dark-accent-text,
    icon: $dark-accent-text,
    icons: $dark-accent-text,
    text: $dark-primary-text,
    slider-min: $dark-primary-text,
    slider-off: rgba($dark-text, 0.26),
    slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$dark-theme-foreground-palette: (base: $light-text,
    divider: $light-dividers,
    dividers: $light-dividers,
    disabled: $light-disabled-text,
    disabled-button: rgba($light-text, 0.3),
    disabled-text: $light-disabled-text,
    elevation: black,
    hint-text: $light-disabled-text,
    secondary-text: $light-accent-text,
    accent-text: $light-accent-text,
    icon: $light-text,
    icons: $light-text,
    text: $light-text,
    slider-min: $light-text,
    slider-off: rgba($light-text, 0.3),
    slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: #fafafa;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#2c2c2c, 20%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

$light-theme-background-palette: (background: $light-background,
    status-bar: $light-bg-darker-20,
    app-bar: $light-bg-darker-5,
    hover: $dark-bg-alpha-4,
    card: $light-bg-lighter-5,
    dialog: $light-bg-lighter-5,
    tooltip: $dark-bg-tooltip,
    disabled-button: $dark-bg-alpha-12,
    raised-button: $light-bg-lighter-5,
    focused-button: $dark-focused,
    selected-button: $light-bg-darker-20,
    selected-disabled-button: $light-bg-darker-30,
    disabled-button-toggle: $light-bg-darker-10,
    unselected-chip: $light-bg-darker-10,
    disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

// Background palette for dark themes.
$dark-theme-background-palette: (background: $dark-background,
    status-bar: $dark-bg-lighter-20,
    app-bar: $dark-bg-lighter-5,
    hover: $light-bg-alpha-4,
    card: $dark-bg-lighter-5,
    dialog: $dark-bg-lighter-5,
    tooltip: $dark-bg-lighter-20,
    disabled-button: $light-bg-alpha-12,
    raised-button: $dark-bg-lighter-5,
    focused-button: $light-focused,
    selected-button: $dark-bg-lighter-20,
    selected-disabled-button: $dark-bg-lighter-30,
    disabled-button-toggle: $dark-bg-lighter-10,
    unselected-chip: $dark-bg-lighter-20,
    disabled-list-option: $dark-bg-lighter-10,
);

// Theme Config

body {
    --primary-color: #2b577f;
    --primary-lighter-color: #bfcdd9;
    --primary-darker-color: #1a3c62;
    --text-primary-color: #{$light-primary-text};
    --text-primary-lighter-color: #{$dark-primary-text};
    --text-primary-darker-color: #{$light-primary-text};
}

$mat-primary: (main: #2b577f,
    lighter: #bfcdd9,
    darker: #1a3c62,
    200: #2b577f, // For slide toggle,
    contrast : (main: $light-primary-text,
        lighter: $dark-primary-text,
        darker: $light-primary-text,
    ));
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

body {
    --accent-color: #34465b;
    --accent-lighter-color: #c2c8ce;
    --accent-darker-color: #212f40;
    --text-accent-color: #{$light-primary-text};
    --text-accent-lighter-color: #{$dark-primary-text};
    --text-accent-darker-color: #{$light-primary-text};
}

$mat-accent: (main: #34465b,
    lighter: #c2c8ce,
    darker: #212f40,
    200: #34465b, // For slide toggle,
    contrast : (main: $light-primary-text,
        lighter: $dark-primary-text,
        darker: $light-primary-text,
    ));
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

body {
    --warn-color: #ff0000;
    --warn-lighter-color: #ffb3b3;
    --warn-darker-color: #ff0000;
    --text-warn-color: #{$light-primary-text};
    --text-warn-lighter-color: #{$dark-primary-text};
    --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: (main: #ff0000,
    lighter: #ffb3b3,
    darker: #ff0000,
    200: #ff0000, // For slide toggle,
    contrast : (main: $light-primary-text,
        lighter: $dark-primary-text,
        darker: $light-primary-text,
    ));
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);

$mat-success: (main: #4caf50,
    lighter: #81c784,
    darker: #388e3c,
    200: #4caf50, // For slide toggle,
    contrast : (main: $light-primary-text,
        lighter: $dark-primary-text,
        darker: $light-primary-text,
    ));
$theme-success: mat.define-palette($mat-success, main, lighter, darker);


$theme: mat.define-light-theme($theme-primary, $theme-accent, $theme-warn);
$altTheme: mat.define-dark-theme($theme-primary, $theme-accent, $theme-warn);


// Theme Init
@include mat.all-component-themes($theme);

.theme-alternate {
    @include mat.all-component-themes($altTheme);
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
    padding: 0 1.15em;
    margin: 0 .65em;
    min-width: 3em;
    line-height: 36.4px;
    // border: none !important;
}

.mat-standard-chip {
    padding: .5em .85em;
    min-height: 2.5em;
}


// change color of mat form field underline
.mat-form-field-appearance-standard:not(.mat-form-field-disabled, .ng-invalid) .mat-form-field-underline::before,
.mat-form-field-appearance-fill:not(.mat-form-field-disabled, .ng-invalid) .mat-form-field-underline::before {
    background-color: #337ab7 !important;
    // height: 10px !important;
    // background-color: rgb(43, 87, 127) !important;
}

.mat-form-field-appearance-standard:not(.mat-form-field-disabled, .ng-invalid) .mat-form-field-ripple,
.mat-form-field-appearance-fill:not(.mat-form-field-disabled, .ng-invalid) .mat-form-field-ripple {
    background-color: #337ab7 !important;
    // background-color: rgb(43, 87, 127) !important;
}
